import { useEffect } from "react"

const Home = () => {
  useEffect(() => {
    window.location = "/highlights"
  })

  return null
}

export default Home
